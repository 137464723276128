import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/layout";
import Youtube from "../../../components/youtube";
import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`How to use inhalers & spacers - by Dr Krithika Ganesh`}</h3>
    <Youtube id="ROdf8XQiV_Q" mdxType="Youtube" />
    <h3>{`Handling Menopausal Symptoms - Dr Swapna Bhaskar`}</h3>
    <Youtube id="358TQlUCwLY" mdxType="Youtube" />
    <h3>{`COVID vaccine awareness - Dr Swapna Bhaskar`}</h3>
    <Youtube id="N5FUqb9-QDg" mdxType="Youtube" />
    <h3>{`World TB Day Initiative`}</h3>
    <Youtube id="l-VsHI1OMJs" mdxType="Youtube" />
    <h3>{`Medicolegal awareness webinar`}</h3>
    <Youtube id="ehvSURwCscU" mdxType="Youtube" />
    <h3>{`Puneeth Rajkumar Effect - Col (Dr) Mohan Kubendra`}</h3>
    <Youtube id="UJRDJ_jOCC8" mdxType="Youtube" />
    <h2>{`Follow AFPI Karnataka`}</h2>
    <a href="https://twitter.com/AFPIKarnataka"><IoLogoTwitter size={56} mdxType="IoLogoTwitter" />/AFPIKarnataka</a>
    <br />
    <a href="https://facebook.com/AFPIKarnataka"><IoLogoFacebook size={56} mdxType="IoLogoFacebook" />/AFPIKarnataka</a>
    <br />
    <a href="https://www.youtube.com/channel/UCSLo6ov-zltSnUCYzXpP_cg"><IoLogoYoutube size={56} mdxType="IoLogoYoutube" /> Youtube</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      